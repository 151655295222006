<template>
  <section>
    <div class="container">
      <span class="name">{{platform}}</span>
      <h1>{{$t("customers.title")}}</h1>

      <div class="customer-logos">
        <a class="item grid-item" :href="`https://${customer.host}`" v-for="customer in customersWithLogo" :key="customer.id" target="_blank">
          <img :src="customer.logo" :alt="customer.name" :title="customer.name" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Customers',
  data () {
    return {
      customers: []
    }
  },

  props: [
    'platform'
  ],

  computed: {
    customersWithLogo: function () {
      return this.customers.filter(function (c) {
        return c.logo !== null
      })
    }
  },

  methods: {
    getCustomersUrl () {
      switch (this.platform) {
        case 'Digicollect':
          return '/sites/digicollect/customers'
        case 'KentaaGo':
          return '/sites/kentaa-go/customers'
        default:
          return `/sites/kentaa/customers/${this.platform}`
      }
    }
  },

  created: function () {
    this.axios.get(`${this.getCustomersUrl()}?limit=30&locale=${this.$i18n.locale}`).then((response) => {
      this.customers = response.data
    })
  }
}
</script>

<style lang="scss" scoped>
section {
  margin: 4rem 0 7rem;

  h1 {
    font-size: 1.5rem;
  }

  .customer-logos {
    margin: 2rem 0 4rem;
    .item {
      --size: 180px;
      transition: all ease-in-out 0.2s;
      box-shadow: 0px 2px 5px rgba(black, 0.2);
      text-align: center;
      border-radius: 20px;
      max-width: var(--size);
      line-height: calc(var(--size) - 15px);
      max-height: var(--size);
      display: inline-block;
      text-align: center;
      margin-top: 2rem;
      padding: 0.5rem;
      vertical-align: top;
      transform: scale(1);

      &:not(:first-child) {
        margin-left: 2rem;
      }

      &:nth-child(2n + 2) {
        --size: 140px;
      }

      &:nth-child(3n + 3) {
        --size: 100px;
      }

      img {
        max-width: 90%;
        display: inline-block;
        max-height: calc(var(--size) * 0.9);
      }

      &:hover {
        transform: scale(1.03);
      }
    }
  }
}
</style>
