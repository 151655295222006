<template>
  <div class="about">
    <Header />
    <Carrousel :title="$t('banner.digicollect.title')" :content="$t('banner.digicollect.content')" :showTabs="true" :extraClass="'smallImage'" :image="require(`@/assets/images/gogogiving-banner.webp`)" />
    <article>
      <div class="container">
        <Breadcrumbs :current="'Digicollect'" />
        <div v-html="$t('content_pages.digicollect')"></div>
        <a href="https://www.digicollect.nl" target="_blank" class="primary-btn pink d-inline-block min-width w-auto mt-3">
          <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{$t('buttons.more_info')}}</span>
          <span class="hover-state position-absolute"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{$t("dictionary.know_more")}}</span>
        </a>
      </div>
    </article>

    <Customers :platform="'Digicollect'" />
  </div>
</template>

<script>
// @ is an alias to /src
import 'bootstrap/dist/css/bootstrap.min.css'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import Carrousel from '@/components/Carrousel.vue'
import Header from '@/components/Header.vue'
import Customers from '@/components/Customers.vue'

export default {
  name: 'About',
  data () {
    return {
      customers: []
    }
  },

  components: {
    Breadcrumbs,
    Carrousel,
    Customers,
    Header
  },

  mounted () {
    this.axios.get(`/sites/digicollect&locale=${this.$i18n.locale}`).then((response) => {
      this.customers = response.data
    })
  },

  metaInfo () {
    return {
      title: this.$t('seo.digicollect.title'),
      meta: [
        { name: 'description', content: this.$t('seo.digicollect.description') },
        { property: 'og:title', content: this.$t('seo.digicollect.title') },
        { property: 'og:description', content: this.$t('seo.digicollect.description') },
        { property: 'og:site_name', content: 'Gogogiving.com' },
        { property: 'og:site_name', content: 'GogoGiving' },
        { property: 'og:url', content: window.location.href },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }
  }
}
</script>
